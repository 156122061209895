import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl'
import {idGen} from "../services/utils";
import cls from "./atoms.module.scss"

interface IProps {
    id?: string,
    type?: string,
    inpValue?: string,
    label?: string,
    value?: string,
    className?: string,
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    withStar?: boolean,
    name?: string,
    defaultValue?: string,
    isUsed?: string,
    code?: string,
    style?: {
        width: string
    },
    required?: string,
    autoComplete?: string
    pattern?: string
    err?: boolean
}

export const CustomTextField: FC<IProps> = ({
                                                type = "",
                                                inpValue = "",
                                                label = "",
                                                value = "",
                                                className = "",
                                                handleChange,
                                                withStar = false,
                                                defaultValue = "",
                                                isUsed = "",
                                                code,
                                                required,
                                                pattern = "",
                                                err = false,
                                                ...props
                                            }) => {

    let newType = type ? type : "text";
    let customClassName = className ? className : "";
    let generatedId = props.name + idGen();
    const newPattern = pattern ? pattern : ""
    const LabelName = <FormattedMessage id={value || "value"} defaultMessage={label || ""}/>
    return (
        <div className={`${cls.custom_input_checkout} ${customClassName}`}>
            <input
                className={`${cls.custom_input_field} ${err && cls.err_field}`}
                id={generatedId}
                type={newType}
                placeholder=" "
                autoComplete="off"
                value={isUsed ? code : inpValue}
                onChange={handleChange}
                disabled={!!isUsed}
                // pattern={newPattern}
                {...props}
                required={!!required}
            />
            <label className={cls.custom_input_label} htmlFor={generatedId}>
                {LabelName}{withStar &&
                <span className={cls["red-fms"]}> *</span>}
            </label>
        </div>
    )
}

