import {apiUrlWithStore} from "../../../helper";

export const postUrlWithStore = (email: string) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        let option: {
            method: string,
            headers: {
                Accept: string,
                "Content-Type": string
            }
            body: string
        } = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({subscriber_email: email})
        }

        fetch(apiUrlWithStore(`/api/subscribe`), option)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    return {message: data.success, class: "text-success"};
                } else {
                    return {message: data.success, class: "text-danger"};
                }
            })
            .catch((error) => console.error(error, "error"));
    } else {
        return {message: "Invalid Email", class: "text-danger"};
    }
}